input {
  border-radius: 26px;
  color: var(--white-color);
  background-color: var(--background-color);
  width: 100%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--white-color);
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: var(--white-color);
  }
}

select {
  border-radius: 26px;
  color: var(--white-color);
  background-color: var(--background-color);
  width: 100%;
  border: none;
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:focus {
    outline: none;
  }

  option {
    color: var(--white-color);
  }
}

.form-group {
  margin-bottom: 1rem;
  position: relative;

  svg-custom {
    position: absolute;
    left: 17px;
    top: 17px;
  }

  .select-arrow {
    right: 17px;
    left: inherit;
    top: 24px;
  }

  .select-image {
    padding: 1rem 3.25rem;
  }

  .select {
    padding: 1rem 3.25rem 1rem 1rem;
  }

  .input-image {
    padding: 1rem 1rem 1rem 3.25rem;
  }

  .input {
    padding: 1rem;
  }
}

.form-color {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 52px;
}

.form-checkbox {
  margin: 0px 10px 1rem;
  display: flex;

  .checkbox {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    .square {
      width: 30px;
      height: 30px;
      border: solid 2px var(--neutral-color-4);
      border-radius: 10px;
      position: relative;
    }
  }

  input[type="checkbox"] {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    color: var(--white-color);
    cursor: pointer;
  }

  input:checked + .checkbox .square::after {
    position: absolute;
    content: "";
    display: flex;
    margin: auto;
    border-radius: 6px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--neutral-color-4);
    width: 22px;
    height: 22px;
  }
}

.form-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  .avatar {
    position: relative;
    .avatar-image {
      border-radius: 50%;
      overflow: hidden;
      width: 100px;
      background-color: var(--primary-color);
      height: 100px;
      img {
        height: inherit;
        width: inherit;
        object-fit: cover;
        object-position: center;
      }
    }
    .edit {
      position: absolute;
      background-color: rgba($color: #182e30, $alpha: 0.5);
      display: flex;
      align-items: center;
      width: 45px;
      right: -10px;
      top: -10px;
      height: 45px;
      border-radius: 50px;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
