.b1 {
  font-size: 26px;
  font-family: poppins-bold;
}

.b2 {
  font-size: 20px;
  font-family: poppins-bold;
}

.b3 {
  font-size: 16px;
  font-family: poppins-bold;
}

.b4 {
  font-size: 14px;
  font-family: poppins-bold;
}

.s1 {
  font-size: 20px;
  font-family: poppins-semibold;
}

.s2 {
  font-size: 16px;
  font-family: poppins-semibold;
}

.s3 {
  font-size: 14px;
  font-family: poppins-semibold;
}

.l1 {
  font-size: 14px;
  font-family: poppins-light;
}

.i1 {
  font-size: 10px;
  font-family: poppins-italic;
}

.r1 {
  font-size: 18px;
  font-family: poppins-regular;
}

.r2 {
  font-size: 16px;
  font-family: poppins-regular;
}

.r3 {
  font-size: 14px;
  font-family: poppins-regular;
}

.r4 {
  font-size: 12px;
  font-family: poppins-regular;
}

.m1 {
  font-size: 16px;
  font-family: poppins-medium;
}

.m2 {
  font-size: 14px;
  font-family: poppins-medium;
}

.m3 {
  font-size: 10px;
  font-family: poppins-medium;
}
