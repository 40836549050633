@font-face {
  font-family: poppins-regular;
  src: url("/assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: poppins-bold;
  src: url("/assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppins-italic;
  src: url("/assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: poppins-semibold;
  src: url("/assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: poppins-light;
  src: url("/assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: poppins-medium;
  src: url("/assets/fonts/Poppins-Medium.ttf");
}
