button {
  border-radius: 26px;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;

  &:active {
    opacity: 0.4;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.button {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.button-outline {
  color: var(--neutral-color-4);
  background-color: transparent;
  border: 2px solid var(--neutral-color-4);
}
