@import "/src/assets/style/font.scss";
@import "/src/assets/style/text.scss";
@import "/src/assets/style/button.scss";
@import "/src/assets/style/input.scss";

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: poppins-regular;
  overflow: hidden;
  background-color: var(--background-color);
}

@media only screen and (max-width: 900px) {
  html,
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

.not-select {
  user-select: none;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
  transition: opacity 0.5s;
}

.flex {
  display: flex;
}

.bg-blur-30-15 {
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.15);
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.error-text {
  color: red;
  font-family: "poppins-light";
  font-size: 12px;
  padding: 0 1rem;
}

.child-pointer {
  * {
    user-select: none;
  }
  *:hover {
    cursor: pointer;
  }
}

app-auth {
  form {
    width: 50%;
    position: relative;
    z-index: 2;
    &::after {
      position: absolute;
      border-radius: 20px 0 0 20px;
      width: 100%;
      height: 100%;
      content: "";
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      backdrop-filter: blur(16px);
      --webkit-backdrop-filter: blur(16px);
      background-color: rgba(255, 255, 255, 0.16);
    }

    .footer {
      position: absolute;
      bottom: 3rem;
      width: calc(100% - 8rem);

      button {
        width: 100%;
        height: 51px;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .image {
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--neutral-color-2);
      opacity: 0.3;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
    }
  }

  .icon-back {
    position: absolute;
    left: 1rem;
    top: 1rem;
    div {
      background-color: var(--background-color);
      border-radius: 50%;
      width: 50px;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-height: 900px) {
    section {
      justify-content: unset !important;
    }
  }

  app-sign-in,
  app-sign-up {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 600px;
    @media only screen and (max-width: 900px) {
      form {
        max-width: 100%;
        width: 500px;
        padding: 2rem 1rem !important;

        &::after {
          display: none;
        }

        .footer {
          width: calc(100% - 2rem);
        }
      }

      .image {
        display: none;
      }
    }
  }
}

.over-flow-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
